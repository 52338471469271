.Card {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  background-color: #555555;
  margin-top: 2em;
  margin-left: 2em;
  position: relative;

  height: calc(48vw - 2em);
  width: calc(48vw - 2em);
  max-height: 32em;
  max-width: 32em;
  
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.15); 
  box-shadow: inset 0px 0px 0px 0.75em #bd8751;
}

.CardContent {
  margin-top: 2.5em;
  margin-left: 2.5em;
  margin-right: 2.5em;
  position: relative;
  width: calc(100% - 5em);
  height: calc(100% - 3.5em);
}

.BigBunny {
  position: absolute;
  top: 0.75em;
  left: 0.75em;
  bottom: 0.75em;
  right: 0.75em;
}

@media only screen and (max-device-width: 480px) {
 .Card {
    width: 100vw;
    height: 100vw;
    margin-left: 0em;
    margin-top: 0em;
    box-shadow: none;
    max-height: 100vw;
    max-width: 100vw;
  }
  
  .CardContent {
    margin-top: 2em;
    margin-left: 2em;
    width: calc(100% - 4em);
    height: calc(100% - 2em);
  }

  .BigBunny {
    position: absolute;
    top: 0em;
    left: 0em;
    bottom: 0em;
    right: 0em;
  }

}
