body {
  margin: 0;
  font-family: "Press Start 2P", "VT323", source-code-pro, Menlo, Monaco, Consolas, "Courier New";
  background: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

input {
  color: white;
  font-family: "Press Start 2P", "VT323", source-code-pro, Menlo, Monaco, Consolas, "Courier New";
}

input:focus {
  outline: none;
}

@media only screen and (max-width: 480px) {
  body {
    font-size: 12px;
  }
}

p {
  font-family: "VT323", "Press Start 2P", source-code-pro, Menlo, Monaco, Consolas, "Courier New";
}
