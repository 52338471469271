.Cell {
  position: absolute;
  border: 2px solid rgba(0,0,0,0.15);
  z-index: 2;
  text-align: center;
  background-color: #9fcf7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Cell[answer='wrong'] {
  background-color: #bd8751;
}

.Cell[answer='correct'] {  
  background-color: #8fdf8d;
}

.CellLabel {
  display: block;
  color: rgba(0,0,0,0.65);
  font-size: 0.9em;
}

.Cell:hover {
}

