.CarrotMeter {
  display: inline-block;
}

.CarrotMeter[flashing='yes'] {
  animation: blinker 0.5s linear 0.2s infinite;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}