.Confetti {
  display: inline-block;
  background-image: url('../assets/images/confetti.gif');
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0em;
  
  /* change both to 100%; */
  width: 100%;
  height: 100%;
  
  image-rendering: optimizeSpeed;             /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges;          /* Firefox                        */
  image-rendering: -o-crisp-edges;            /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Chrome */
  image-rendering: optimize-contrast;         /* CSS3 Proposed                  */
}