.Bunny {
  display: block;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
}

.BunnyImage {
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0em 0em;
  width: 100%;
  height: 100%;
  image-rendering: optimizeSpeed;             /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges;          /* Firefox                        */
  image-rendering: -o-crisp-edges;            /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Chrome */
  image-rendering: optimize-contrast;         /* CSS3 Proposed                  */
}

.BunnyImage[action='earflop'][pad='top'] {
  background-image: url('../assets/images/bunny-earflop-full.gif');
}

.BunnyImage[action='earflop'][pad='no'] {
  background-image: url('../assets/images/bunny-earflop.gif');
}

.BunnyImage[action='earlift'][pad='top'] {
  background-image: url('../assets/images/bunny-ear-lift.gif');
}

.BunnyImage[action='still'][pad='top'] {
  background-image: url('../assets/images/bunny-ear-flop-still.gif');
}

.BunnyImage[action='still'][pad='no'] {
  background-image: url('../assets/images/bunny-still.gif');
}

.BunnyImage[action='crying'][pad='top'] {
  background-image: url('../assets/images/bunny-crying-full.gif');
}

.BunnyImage[action='tear'][pad='top'] {
  background-image: url('../assets/images/bunny-tear-from-flop-loop.gif');
}


