.HeartMeter {
  position: absolute;
  left: 0em;
  bottom: 1em;
  width: 6em;
  text-align: left;
}

.HeartMeter[flashing='yes'] {
  animation: blinker 0.5s linear 0.2s infinite;
}


@keyframes blinker {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@media only screen and (max-width: 480px) {
  .HeartMeter {
    font-size: 1.25em;
  }
}


